import store from "@/store";

export function checkPlatform (platformType) {
    if (platformType == 'rep-quote') {
        return false
    } else {
        return true
    }
}

export function checkExtraHealthGroup (landingGroupCode) {
    let employeeEnrollmentCode = store?.getters?.getEmployeeGroupCode
    if (landingGroupCode?.data?.is_extra_health) {
        return true
    } else if (employeeEnrollmentCode == 'EXT9416') {
        return true
    } else {
        return false
    }
}

<template>
  <div class="extra-health-container d-flex justify-content-start" v-if="!(paymentInfo.status)">
    <div>
      <div class="sms-alert ">
        <i class="fa fa-exclamation-circle fa-3x sms-icon-danger"></i>
        <div class="sms-alert-danger">
          <span class="sms-msg-text"> {{paymentInfo.failureMessage}} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ExtraHealthAlertMessage",
  data() {
    return {
      smsStatus: false,
      messageText: 'Payment failed due to insufficient balance in your account. Please check your balance and try again',
      renderAlertPopup: false
    };
  },
  props: {
    paymentInfo: {
      type: Object
    }
  }
}
</script>

<style scoped>
.extra-health-container .sms-alert {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.extra-health-container .sms-alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 15px 8px;
  margin: 18px 12px;
  border-radius: 5px;
}

.extra-health-container .sms-alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 15px 8px;
  margin: 18px 12px;
  border-radius: 5px;
}

.extra-health-container .sms-alert i {
  margin-right: 10px;
  font-size: 24px;
  display: inline-block;
}

.extra-health-container .sms-icon-success {
  color: #28a745;
}

.extra-health-container .sms-icon-danger {
  color: #dc3545;
}

.extra-health-container .sms-msg-text {
  padding: 0 6px;
  font-size: 15px;
}

</style>
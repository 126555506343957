
export function CheckWebAccess (landingUrl, tempId, params) {
  let queryParams = {}
  if (tempId) {
    queryParams = params
    queryParams['enrollment_id'] = tempId
    queryParams['web_access'] = landingUrl
    const queryString = new URLSearchParams(queryParams).toString();
    return `${process.env.VUE_APP_API_BASE}/get-common-menu?${queryString}`;
  } else {
    queryParams = params
    queryParams['web_access'] = landingUrl
    const queryString = new URLSearchParams(queryParams).toString();
    return `${process.env.VUE_APP_API_BASE}/get-common-menu?${queryString}`;
  }
}


